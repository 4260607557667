import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo'].includes(prop)
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	padding: 24px;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0;
	}
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(40)};
`
const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	color: ${({ theme }) => theme.palette.common.white};
	margin-bottom: 22px;
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		margin-bottom: 5px;
	}
`
const Description = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	color: ${({ theme }) => theme.palette.common.white};
	margin-top: 32px;
	line-height: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-top: 48px;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '40px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: ${({ theme }) => theme.spacing(2)};
		margin-top: ${({ hasSiblings, theme }) => (hasSiblings ? theme.spacing(6.5) : '0')};
	}
	${({ theme }) => theme.breakpoints.down('md')} {
		align-items: center;
		flex-basis: fit-content;
		margin: ${({ theme }) => theme.spacing(5, 'auto', 0)};
		width: fit-content;
		> .MuiGrid-root {
			flex-basis: 100%;
			width: 100%;
			a {
				width: inherit;
			}
		}
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton)`
	background-color: transparent;
	color: ${({ theme }) => theme.palette.common.white};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
	&:not(.MuiButton-text) {
		border: 2px solid ${({ theme }) => theme.palette.common.white};
	}
	&:hover {
		border-color: transparent;
		&:not(.MuiButton-text) {
			background-color: ${({ theme }) => theme.palette.common.black};
		}
	}
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
