import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	![
		'autoplayAlways',
		'hide',
		'horizontalAlign',
		'light',
		'originalHeight',
		'originalWidth',
		'playIconHorizontalAlign',
		'playIconVerticalAlign',
		'playing',
		'startOnEveryInView',
		'stopOnEveryOutView',
		'template',
		'thresholdInView',
		'verticalAlign',
		'additionalContent',
		'subtitleProps',
		'hasVideo',
		'aboveTheFold',
	].includes(prop)

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const OverlayContainer = styled('div')`
	position: absolute;
	z-index: 2;
	margin: 41px 24px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 77px 40px;
	}
`
const Root = styled(Grid, { shouldForwardProp })`
	align-items: flex-end;
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	position: relative;
	${OverlayContainer} {
		text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
	}
	&.hero-small {
		${OverlayContainer} {
			${({ theme }) => theme.breakpoints.up('md')} {
				margin: 100px 30px 22px;
			}
		}
	}
`

export { Root, OverlayContainer }
